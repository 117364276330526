* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeSpeed;
}

html,
body {
  font-size: 16px;
  font-family: Montserrat, sans-serif, serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  line-height: normal;
}

body {
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.main-container {
  display: flex;
  width: 84.72222%;
  margin: auto;
}

ul {
  list-style-type: none;
}

@media only screen and (max-width: 1199px) {
  #root {
    height: 100%;
    display: grid;
    grid-template-areas:
      'header'
      'body'
      'footer';
  }

  .main-container {
    width: 100%;
  }

  .menu-open {
    overflow: hidden;
    -webkit-overflow-scrolling: auto !important;
  }
}
